/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; font-size: 10px; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
  font-family: 'Mr Eaves XL Sans';
  src: url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-book-webfont.eot');
  src: url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-book-webfont.eot?#iefix') format('embedded-opentype'), url('https://hearingtools.blob.core.windows.net/dev-hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-book-webfont.woff2') format('woff2'), url('https://hearingtools.blob.core.windows.net/dev-hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-book-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Mr Eaves XL Sans';
  src: url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-reg-webfont.eot');
  src: url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-reg-webfont.eot?#iefix') format('embedded-opentype'), url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-reg-webfont.woff2') format('woff2'), url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-reg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
    font-family: 'Mr Eaves XL Sans';
    src: url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-bold-webfont.eot');
    src: url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-bold-webfont.eot?#iefix') format('embedded-opentype'), url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-bold-webfont.woff2') format('woff2'), url('https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/mreavesweb/mreavesxlsanot-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
  font-family: "DIN Next";
  src: url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/din/21159f5e-cd9e-408a-ba51-65510b4a13aa.eot?#iefix");
  src: url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/din/21159f5e-cd9e-408a-ba51-65510b4a13aa.eot?#iefix") format("eot"), url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/din/f3de66f3-8e63-467b-a343-dad3b4e2d400.woff2") format("woff2"), url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/din/20007504-6ea2-4d68-93e4-f4769b5d9f14.woff") format("woff"), url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/din/1c35aab7-8ddd-402f-8976-1363134c3817.ttf") format("truetype"), url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/din/91c8307f-e301-4d44-b0f3-5cff17b3a554.svg#91c8307f-e301-4d44-b0f3-5cff17b3a554") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'OpenSans';
  src: url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/OpenSans/opensans-regular-webfont.eot");
  src: url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/OpenSans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/OpenSans/opensans-regular-webfont.woff2") format("woff2"), url("https://hearingtools.blob.core.windows.net/hearingtools/HearingTools/fonts/OpenSans/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  height: 150px;
  margin: 6vh 0 3rem;
}
.icon img {
  height: 100%;
}

.content {
  font-family: 'DIN Next', Arial, sans-serif;
  color: #000;
  font-size: 2.1rem;
  padding: 2rem 4rem;
}

.contentAudibel {
  font-family: 'TheSans_B2_300_Light', Arial, sans-serif;
  color: #000;
  font-size: 2.1rem;
  padding: 2rem 4rem;
}

.content a {
  text-decoration: underline;
  color: #1e9be9;
}

.contentAudibel a {
  text-decoration: underline;
  color: #739600;
}


